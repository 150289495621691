<template>
  <section class="page-body">
    <TitleBar :title="$t('beneficiary_summary')" />
    <v-container>
      <loading
        :active="loader"
        :is-full-page="fullPage"
        :opacity="0.7"
        color="#ff6b00"
        :height="height"
        :width="width"
      />
      <v-alert
        prominent
        dense
        text
        type="error"
        v-model="alert"
        dismissible
        border="left"
        v-if="isexceedBenelimit"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ $t("bene_limit_exceed_text") }}
            <span class="primary--text font-weight-bold"> {{ $t("addBeneficiary") }}</span
            >.
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-card>
        <v-card-title>
          <v-btn
            :disabled="isexceedBenelimit"
            v-if="!is_self_beneficiary"
            color="primary"
            class="text-capitalize text-decoration-none"
            to="/beneficiary"
            link
          >
            {{ $t("addBeneficiary") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="filter"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :items="userItems"
          :headers="headers"
          :search="filter"
          :hide-default-header="hideHeaders()"
        >
          <template v-slot:item.first_name="{ item }">
            <div class="d-flex">
              <div :class="webbreakdown() ? 'd-flex width-100' : 'd-flex'">
                <div class="d-flex center" v-if="webbreakdown()">
                  <span class="text-center">
                    <flag
                      :iso="getIndCountry(item.country_code)"
                      :class="webbreakdown() ? 'flag-style' : 'flag-size'"
                    />
                  </span>
                </div>
                <div>
                  <p
                    :class="
                      webbreakdown()
                        ? 'm-0 mt-2 ms-3 font-name p-0'
                        : 'm-0 mt-2 ms-3  p-0'
                    "
                  >
                    {{ `${item.full_name}` }}
                  </p>
                  <p class="m-0 ms-3 p-0">
                    <span v-if="webbreakdown()">{{
                      item.rec_type === "BUS" ? "Business" : "Individual"
                    }}</span>
                  </p>
                  <div class="d-flex" v-if="webbreakdown()">
                    <div class="icon-size d-flex center ps-2 pe-2">
                      <v-icon v-if="item.rec_type === 'IND'">{{
                        item.payment_code === "B" ? "mdi-bank" : "mdi-cash"
                      }}</v-icon>
                      <v-icon v-if="item.rec_type === 'BUS'">mdi-bank</v-icon>
                      <!-- <v-icon>mdi-cash</v-icon> -->
                    </div>

                    <div
                      class="text-col"
                      v-if="item.payment_code != 'B' && item.rec_type == 'IND'"
                    >
                      <span> {{ item.location_name }}</span>
                    </div>
                    <div class="text-col" v-if="item.payment_code === 'A'">
                      <span> {{ $t("Policy_Number") }}:{{ item.policy_number }}</span>
                    </div>
                    <div class="text-col" v-else>
                      <span class="">
                        {{ item.location_name }}
                      </span>
                      <p class="p-0 m-0">{{ item.bank_account_no }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div cols="1" sm="1" class="d-flex center" v-if="webbreakdown()">
                <i class="fa-solid fa-chevron-right"></i>
              </div> -->
            </div>
            <div class="d-flex justify-end" v-if="webbreakdown() && !is_self_beneficiary">
              <v-btn
                :disabled="item.is_txn"
                class="mr-1 action-btn"
                title="Update Beneficiary"
                @click="editRemittee(item)"
                icon
              >
                <v-icon>mdi-account-edit-outline</v-icon>
              </v-btn>
              <v-btn
                class="mr-2 action-btn"
                title="Delete Beneficiary"
                @click="openDialogCOnfirmation(item)"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                @click="info(item, $event.target)"
                class="mr-1 action-btn"
                title="Start Transaction"
                icon
              >
                <v-icon class="mdi-rotate-315">mdi-send</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.country_name="{ item }">
            <div v-if="mobilebreakdown()">
              <div class="d-flex" v-if="mobilebreakdown()">
                <p class="text-center m-0">
                  <flag
                    :iso="getIndCountry(item.country_code)"
                    :class="webbreakdown() ? 'flag-style' : 'flag-size'"
                  />
                </p>
                <div class="d-flex">
                  <p
                    :class="
                      webbreakdown()
                        ? 'm-0 mt-2 ms-3 font-name p-0'
                        : 'm-0 mt-2 ms-3  p-0'
                    "
                  >
                    {{ item.rec_type === "BUS" ? item.country_code : item.country_code }}
                  </p>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:item.rec_type="{ item }">
            <span v-if="mobilebreakdown()">{{
              item.rec_type === "BUS" ? "Business" : "Individual"
            }}</span>
          </template>

          <template v-slot:item.location_name="{ item }">
            <div v-if="mobilebreakdown()">
              <div v-if="item.payment_code == 'B' || item.rec_type == 'BUS'">
                {{ $t("name_beneficary") }}: {{ item.location_name }}
              </div>
              <div v-if="item.payment_code == 'B' || item.rec_type == 'BUS'">
                {{ $t("account_no") }}: {{ item.bank_account_no }}
              </div>
              <div v-if="item.payment_code == 'B' || item.rec_type == 'BUS'">
                {{ $t("ifsc_code_beneficary") }}: {{ item.branch_code }}
              </div>
              <div v-if="item.payment_code == 'B' && item.rec_type == 'BUS'">
                {{ $t("swift_code_branch") }}: {{ item.swift_no }}
              </div>

              <div
                v-if="
                  item.payment_code != 'B' &&
                  item.payment_code != 'A' &&
                  item.rec_type == 'IND'
                "
              >
                {{ $t("payment_location") }}: {{ item.location_name }}
              </div>
              <div v-if="item.payment_code === 'A' && item.rec_type == 'IND'">
                {{ $t("Policy_Number") }}:{{ item.policy_number }}
              </div>
            </div>
          </template>

          <template v-slot:item.payment_code="{ item }">
            <span v-if="mobilebreakdown()">
              {{
                item.rec_type == "IND"
                  ? getpaymentMode(item.payment_code)
                  : "Bank Transfer"
              }}</span
            >
          </template>

          <template v-slot:item.currency_code="{ item }">
            <span v-if="mobilebreakdown()"> {{ item.currency_code }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex" v-if="mobilebreakdown() && !is_self_beneficiary">
              <v-btn
                :disabled="item.is_txn"
                class="mr-2 action-btn"
                :title="$t('update_bene_lable')"
                @click="editRemittee(item)"
                icon
              >
                <v-icon>mdi-account-edit-outline</v-icon>
              </v-btn>
              <v-btn
                class="mr-2 action-btn"
                :title="$t('delete_bene_label')"
                @click="openDialogCOnfirmation(item)"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                @click="info(item, $event.target)"
                class="mr-2 action-btn"
                :title="$t('bene_start_txn')"
                icon
              >
                <v-icon class="mdi-rotate-315">mdi-send</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <!-- Delete confirmation popup dialog -->
      <v-dialog v-model="openDialog" width="500">
        <v-card>
          <v-card-title class="border border-bottom">
            {{ $t("delete_beneficary") }}</v-card-title
          >
          <!-- <v-divider></v-divider> -->
          <v-card-text class="h5 py-3">
            {{ $t("are_you sure want_to_delete_beneficary") }}
            <strong
              >{{ remitee_selected.first_name }}
              {{ remitee_selected.middle_name }}
              {{ remitee_selected.last_name }}</strong
            >?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-capitalize"
              outlined
              @click="onDeleteDetil"
            >
              {{ $t("yes") }}<v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="text-capitalize"
              outlined
              @click="openDialog = false"
              >{{ $t("no") }} <v-icon>mdi-close</v-icon></v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
import TitleBar from "@/components/navigations/TitleBar.vue";
// import axios from "axios";
import remitteService from "@/services/RemitteService/remitteService.js";
import alerts from "@/mixins/alerts";
// import EventBus from "../../plugins/events.js";
export default {
  name: "BeneficiarySummary",
  mixins: [alerts],
  components: {
    TitleBar,
    Loading,
  },
  data() {
    return {
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      isexceedBenelimit: false,
      filter: null,
      headers: [
        {
          text: this.$t("name"),
          value: "first_name",
          align: "left",
        },
        {
          text: this.$t("country"),
          value: "country_name",
          align: "left",
        },
        {
          text: "",
          value: "rec_type",
          align: "left",
        },
        {
          text: this.$t("bank_details"),
          value: "location_name",
          align: "left",
        },
        {
          text: this.$t("paymentType"),
          value: "payment_code",
          align: "left",
        },
        {
          text: this.$t("currency"),
          value: "currency_code",
          align: "left",
        },
        {
          text: this.$t("action"),
          value: "action",
          align: "center",
        },
      ],
      userItems: [],
      loader: false,
      fullPage: true,
      height: 100,
      width: 100,
      payment_code_list: [
        { text: "Bank Transfer", value: "B" },
        { text: "Cash Payment", value: "C" },
        { text: "Home Delivery", value: "13" },
        { text: "E/Mobile-Wallet", value: "W" },
        { text: "Instant Credit", value: "I" },
        { text: "BPJS", value: "A" },
        { text: "Digital Currency", value: "15" },
      ],
      openDialog: false,
      is_self_beneficiary: null,
      remitee_selected: {},
      country_obj: [],
    };
  },
  created() {
    this.is_self_beneficiary = JSON.parse(sessionStorage.getItem("is_self_beneficiary"));
  },
  async mounted() {
    await this.getBeneLimit();
    await this.getBenficiary();
    this.$emit("getProfile");
    await this.getCountryList();
  },
  methods: {
    getIndCountry(val) {
      let value = "";
      this.country_obj.map((i) => {
        if (val === i.iso_alpha3) {
          value = i.iso_alpha2;
        }
      });
      return value;
    },
    async getCountryList() {
      let responseData = await this.$store.dispatch(
        "Authentication/getBeneficiaryCountryList"
      );
      responseData.data.data.map((i) => {
        this.country_obj.push(i);
      });
      console.log("country_obj", this.country_obj);
    },
    webbreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    hideHeaders() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    mobilebreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    getpaymentMode(val) {
      if (val === "B" || val === "11") {
        return "Bank Transfer";
      }
      if (val === "C") {
        return "Cash Pickup";
      }
      if (val === "A") {
        return "BPJS";
      }
      if (val === "W") {
        return "E/Mobile-Wallet";
      }
    },
    async getBeneLimit() {
      try {
        const responseData = await remitteService.getBeneLimit();
        if (responseData.data.status_code === 200) {
          console.log("Bene Limit", responseData.data.data[0].is_limit_exceed);
          this.isexceedBenelimit = responseData.data.data[0].is_limit_exceed;
        } else {
          this.isexceedBenelimit = false;
        }
      } catch (e) {
        this.isexceedBenelimit = false;
        console.error(e);
      }
    },
    async getBenficiary() {
      try {
        const responseData = await remitteService.getremitteeList();
        console.log("responseData.data", responseData.data);
        if (responseData.data.status_code === 200) {
          console.log("responseData.data", responseData.data);
          this.userItems = [...responseData.data.data.reverse()];
        } else {
          //to hide error msg while user is not registered (user need to login if regstered)
          let registered = sessionStorage.getItem("complete_registration");
          if (registered === "true") {
            this.showAlertError("Error while fetching summary list");
            // this.alertMsg.status = true;
            // this.alertMsg.type = "error";
            // this.alertMsg.text = "Error while fetching summary list";
            // this.alertMsg.color = "warning";
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    openDialogCOnfirmation(remitee) {
      console.log("logss", remitee);
      this.remitee_selected = remitee;
      this.openDialog = true;
    },
    async onDeleteDetil() {
      try {
        // reset
        this.openDialog = false;

        this.loader = true;
        // const responseData = await this.$store.dispatch(
        //   "Remittee/deleteRemittee",
        //   this.remitee_selected.receiver_id
        // );
        const responseData = await remitteService.deleteRemittee(
          this.remitee_selected.receiver_id
        );
        if (responseData && responseData.data.status_code === 200) {
          this.getBenficiary();
          this.getBeneLimit();
          this.loader = false;
          // this.alertMsg.status = true;
          // this.alertMsg.type = "success";
          // this.alertMsg.text = this.$t("beneficiary_delete_success_msg");
          // this.alertMsg.color = "green";
          this.showAlertSuccess(this.$t("beneficiary_delete_success_msg"));
        } else {
          this.loader = false;
          // this.alertMsg.status = true;
          // this.alertMsg.type = "error";
          // this.alertMsg.text = responseData.response.error.message;
          // this.alertMsg.color = "warning";
          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    async info(item) {
      console.log("item: ", item);
      this.loader = true;
      try {
        // const responseData = await this.$store.dispatch(
        //   "Remittee/getRemitteDetailByID",
        //   item.receiver_id
        // );
        const responseData = await remitteService.getRemitteDetailByID(item.receiver_id);
        console.log("res start", responseData);
        if (responseData.data.status_code === 200) {
          this.loader = false;
          //to send selected bene data to the send money page
          this.$store.state.data = responseData.data.data[0];
          this.$router.push("/transaction");
        } else {
          this.loader = false;
          // this.alertMsg = {
          //   status: true,
          //   text: "something went wrong.",
          //   color: "warning",
          //   type: "warning",
          // };
          this.showAlertError("something went wrong.");
        }
      } catch (err) {
        this.loader = false;
        console.log(err);
      }
    },
    editRemittee(item) {
      if (item.rec_type == "BUS")
        this.$router.push({
          path: "/beneficiary-bus",
          query: { Remittee_ID: item.receiver_id },
        });
      else
        this.$router.push({
          path: "/beneficiary",
          query: { Remittee_ID: item.receiver_id },
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/global.scss";
.row {
  margin: 5px;
}
.action-btn {
  // background: $primary;
  border-radius: 4px;
  // color: $white !important;
  border: none !important;
}
.action-btn:hover {
  color: $primary !important;
}
.action-div {
  text-align: end;
}
::v-deep .table {
  td {
    vertical-align: middle !important;
  }
}
::v-deep th {
  color: $txt !important;
  font-size: 14px;
  font-weight: 600;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  height: auto !important;
  min-height: 2px !important;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: left !important;
}
.text-col {
  color: rgb(130, 127, 127);
  font-size: 12px;
}
.center {
  justify-content: center;
  align-items: center;
}
.flag-style {
  font-size: 46px;
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.flag-size {
  font-size: 33px;
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.width-100 {
  width: 78vw;
}
.font {
  font-size: 9px;
}
.font-name {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
</style>
